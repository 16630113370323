export const CLEAN_STR = (str) => {
  return str.toLowerCase().replace(' ', '-');
};

export const HEX_TO_RGB = (hex) => {
  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');
    return [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',');
  }
  throw new Error('Bad Hex');
};

// https://medium.com/@mikeh91/conditionally-adding-keys-to-javascript-objects-using-spread-operators-and-short-circuit-evaluation-acf157488ede
export const buildSEOfromObj = (c) => ({
  ...(c.title && { title: c.title }),
  ...(c.description && { description: c.description }),
  ...(c.keywords && { keywords: c.keywords }),
  ...(c.noIndex && { noindex: c.noIndex }),
  ...{
    openGraph: {
      ...(c.ogtitle && { title: c.ogtitle }),
      ...(c.ogdescription && { description: c.ogdescription }),
      ...(c.ogimage && {
        images: [
          {
            url: c.ogimage.url,
            width: 800,
            height: 600,
            alt: c.ogimage.title || '',
          },
        ],
      }),
    },
  },
});
