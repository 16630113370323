import React, { useEffect, useRef, useState } from 'react';
import styles from './BackgroundTransitions.module.scss';
import lottie from 'lottie-web';
import useStore from '../../../store/Store';

const BackgroundTransitions = ({
  onInComplete = () => {},
  onOutComplete = () => {},
}) => {
  const [firstRender, setFirstRender] = useState(true);
  const isOpen = useStore((state) => state.isOpen);

  const transtitionInRef = useRef(null);
  const transtitionOutRef = useRef(null);
  const animIn = useRef(null);
  const animOut = useRef(null);

  const getIndex = () => {
    const min = 1,
      max = 5;
    return Math.floor(Math.random() * (max - min + 1) + min);
  };

  const loadAnimIn = () => {
    if (animIn.current) animIn.current.destroy();

    animIn.current = lottie.loadAnimation({
      container: transtitionInRef.current,
      renderer: 'svg',
      loop: false,
      autoplay: false,
      path: '/lottie/menu/Anim_menu_' + getIndex() + '_in.json',
      rendererSettings: {
        preserveAspectRatio: 'xMaxYMax slice',
      },
    });
  };

  const loadAnimOut = () => {
    if (animOut.current) animOut.current.destroy();

    animOut.current = lottie.loadAnimation({
      container: transtitionOutRef.current,
      renderer: 'svg',
      loop: false,
      autoplay: false,
      path: '/lottie/menu/Anim_menu_' + getIndex() + '_out.json',
      rendererSettings: {
        preserveAspectRatio: 'xMaxYMax slice',
      },
    });
  };

  const playAnimationIn = () => {
    transtitionInRef.current.style.display = 'block';
    animIn.current.goToAndPlay(0);
    animIn.current.onComplete = () => {
      onInComplete();
      loadAnimOut();
      transtitionOutRef.current.style.display = 'block';
    };
  };

  const playAnimationOut = () => {
    transtitionInRef.current.style.display = 'none';
    animOut.current.goToAndPlay(0);
    animOut.current.onComplete = () => {
      onOutComplete();
      loadAnimIn();
      transtitionOutRef.current.style.display = 'none';
    };
  };

  const immidiateClose = () => {
    transtitionInRef.current.style.display = 'none';
    loadAnimIn();
    transtitionOutRef.current.style.display = 'none';
  };

  // Preload Animations when the DOM is print
  useEffect(() => {
    loadAnimIn();
    loadAnimOut();

    return () => {
      animIn.current.destroy();
      animOut.current.destroy();
    };
  }, []);

  // Toggle transitions
  useEffect(() => {
    if (firstRender) return setFirstRender(false);

    if (!isOpen) {
      playAnimationOut();
    } else {
      playAnimationIn();
    }
  }, [isOpen]);

  return (
    <>
      <div
        className={styles.BackgroundTransitionIn}
        ref={transtitionInRef}
      ></div>
      <div
        className={styles.BackgroundTransitionOut}
        ref={transtitionOutRef}
      ></div>
    </>
  );
};

export default BackgroundTransitions;
