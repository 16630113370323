export default {
  defaultTitle: 'ABECEDAIRE, studio de développement application mobile et web',
  description:
    "ABECEDAIRE est un studio de développement d'application mobile et de site internet. Nous vous accompagnons dans le développement de votre nouvelle solution sur-mesure",
  canonical: 'https://abecedaire-studio.com/',
  openGraph: {
    type: 'website',
    locale: 'fr_FR',
    url: 'https://abecedaire-studio.com/',
    site_name: 'ABECEDAIRE',
    images: [
      { url: 'https://abecedaire-studio.com/opengraph/meta-global.png' },
    ],
    description:
      'La réussite de votre projet est notre priorité. Nous sommes à l’écoute de vos contraintes et de vos ressentis pour vous proposer les solutions et la prestation qui conviendra à vos besoins.',
  },
  twitter: {
    cardType: 'summary_large_image',
  },
  additionalLinkTags: [
    {
      rel: 'icon',
      href: 'https://abecedaire-studio.com/favicon.ico',
    },
    {
      rel: 'apple-touch-icon',
      href: 'https://abecedaire-studio.com/apple-touch-icon.png',
      sizes: '180x180',
    },
    {
      rel: 'icon',
      href: 'https://abecedaire-studio.com/favicon-32x32.png',
      sizes: '32x32',
    },
    {
      rel: 'icon',
      href: 'https://abecedaire-studio.com/favicon-16x16.png',
      sizes: '16x16',
    },
    {
      rel: 'mask-icon',
      href: 'https://abecedaire-studio.com/safari-pinned-tab.svg',
      sizes: '16x16',
    },
    {
      rel: 'manifest',
      href: '/manifest.json',
    },
  ],
};
