import React, { useEffect } from 'react';
import Head from 'next/head';
import Device from '../utils/mobile';
import { useRouter } from 'next/router';
import BackgroundTransitions from './Utils/BackgroundTransitions/BackgroundTransitions';

const AppLayout = ({ children }) => {
  const router = useRouter();

  useEffect(() => {
    Device.check();
    window.addEventListener('resize', Device.check);

    return () => {
      window.removeEventListener('resize', Device.check);
    };
  });

  return (
    <>
      <Head>
        <meta charSet='utf-8' />
        <meta name='viewport' content='initial-scale=1.0, width=device-width' />
      </Head>

      {children}
    </>
  );
};

export default AppLayout;
