import '../styles/globals.scss';
import AppLayout from '../components/AppLayout';
import {
  DefaultSeo,
  NextSeo,
  CorporateContactJsonLd,
  LocalBusinessJsonLd,
  LogoJsonLd,
  SocialProfileJsonLd,
} from 'next-seo';
import SEO from '../../next-seo.config';
import { buildSEOfromObj } from '../utils/string';
import React from 'react';

function MyApp({ Component, pageProps }) {
  const propsKey = Object.keys(pageProps)[0];
  const seoObj =
    propsKey && pageProps[propsKey] ? pageProps[propsKey].seo : false;
  const pageSEO = seoObj ? buildSEOfromObj(seoObj) : {};

  return (
    <AppLayout>
      <DefaultSeo {...SEO} />

      <NextSeo {...pageSEO} />

      <CorporateContactJsonLd
        url='https://abecedaire-studio.com'
        logo='https://abecedaire-studio.com/icon-abecedaire.png'
        contactPoint={[
          {
            telephone: '+330630551601',
            contactType: 'customer service',
            contactOption: 'TollFree',
            areaServed: 'FR',
            availableLanguage: ['French', 'English'],
          },
          {
            telephone: '+330630551601',
            contactType: 'technical support',
            contactOption: 'TollFree',
            areaServed: ['FR'],
            availableLanguage: ['French', 'English'],
          },
        ]}
      />
      <LocalBusinessJsonLd
        type='ProfessionalService'
        id='https://abecedaire-studio.com'
        name='ABECEDAIRE • Développement web et application mobile'
        description="ABECEDAIRE est un studio de développement d'application mobile et de site internet"
        url='https://abecedaire-studio.com'
        telephone='+330630551601'
        address={{
          streetAddress: "11 rue de l'Avalasse",
          addressLocality: 'Rouen',
          postalCode: '76000',
          addressCountry: 'FR',
        }}
        geo={{
          latitude: '49.448855973216226',
          longitude: '1.0971905194135914',
        }}
        openingHours={[
          {
            opens: '09:00',
            closes: '18:00',
            dayOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
            validFrom: '2020-01-01',
            validThrough: '2025-01-01',
          },
        ]}
        areaServed={[
          {
            geoMidpoint: {
              latitude: '49.448855973216226',
              longitude: '1.0971905194135914',
            },
            geoRadius: '1000',
          },
        ]}
      />
      <LogoJsonLd
        logo='https://abecedaire-studio.com/icon-abecedaire.png'
        url='https://abecedaire-studio.com'
      />
      <SocialProfileJsonLd
        type='Organization'
        name='ABECEDAIRE'
        url='https://abecedaire-studio.com'
        sameAs={['https://www.linkedin.com/company/abecedaire/']}
      />
      <Component {...pageProps} />
    </AppLayout>
  );
}

export default MyApp;
