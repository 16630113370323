import create from 'zustand';
import { devtools } from 'zustand/middleware';

const store = (set) => ({
  isOpen: false,
  open: () => set(() => ({ isOpen: true })),
  close: () => set(() => ({ isOpen: false })),
});

const useStore = create(devtools(store));

export default useStore;
