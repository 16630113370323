class cDevice {
  isMobile = false;

  check = () => {
    this.isMobile = window.matchMedia('(max-width: 800px)').matches;

    return this.isMobile;
  };
}

const Device = new cDevice();
export default Device;
